
import axios from 'axios';
export default {
    
    async createDriverBags(params)  {
        return await axios.post(`driver_bags/create` , params)
    },
    async createDriverBagsList(params)  {
        return await axios.post(`driver_bags/create/list` , params)
    },
    async updateDriverBagsColumn(column , value , data)  {
        return await axios.put(`driver_bags/update_list?${column}=${value}` , data)
    },
    async deleteDriverBagsList(list)  {
        return await axios.delete(`driver_bags/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportDriverBags(column , value)  {
        return await axios.get(`driver_bags/report?${column}=${value}`)
    },
    async getAllDriverBags()  {
        return await axios.get(`driver_bags/all`)
    },
    async getOneDriverBags(driver_bag_id)  {
        return await axios.get(`driver_bags/all/${driver_bag_id}`)
    },
    async getDriverBagsByColumn(column , value)  {
        return await axios.get(`driver_bags/filter?column=${column}&value=${value}`)
    },
    async deleteDriverBags(driver_bag_id)  {
        return await axios.delete(`driver_bags/delete/${driver_bag_id}`)
    },
    async updateDriverBags(driver_bag_id , params)  {
        return await axios.put(`driver_bags/update/${driver_bag_id}` , params)
    }
}